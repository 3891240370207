.TermToggler {
    width: "81.5%";
    position: relative;
    display: flex;
    justify-content: space-around;
    text-transform: uppercase;
}

.term-dropdown-text {
    /* position: absolute; */
    font-size: 24px;
    text-transform: uppercase;
    color: #767676;
    margin: 0;
    transition: .4s ease;
    overflow: hidden;
}

.empty {
    margin: 0;
    padding: 0;
    opacity: 0;
    transition: .4s ease;
}

.selected {
    opacity: 1;
    position: absolute;
    transition: .4s ease;
}

.bounce {
    transition: .4s ease;
    opacity: 1;
    margin: 15px 0 0 0;
}

.dropdownColumn {
    display: flex;
    height: auto;
    position: absolute;
    flex-direction: column;
    align-items: flex-start;
    /* border: solid 1px gray; */
    margin-top: -23px;
    margin-left: -56px;
    transition: .4s ease;
    padding: 11px;
}

.dropdownColumnFillBackground {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 18px;
    transition: .4s ease;
}