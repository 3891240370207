body {
	font-family: "Ubuntu", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
	box-sizing: border-box;
	margin: 0;
}

html {
	scroll-behavior: smooth;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

h1,
h2,
h3 {
	font-family: "Ubuntu", sans-serif;
}

h4 {
	font-family: "Ubuntu", sans-serif;
	font-weight: bold;
	font-size: 18px;
	line-height: 28px;
	text-align: center;
	text-transform: uppercase;
}

a {
	text-decoration: none;
}
