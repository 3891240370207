.container--splash {
	background: url(../assets/logoPrimary.svg) center center no-repeat;
    overflow: hidden;
}

.container--fullscreen {
	height: 100vh;
	width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

/* BEM in action */

.fadeIn {
        -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
}

    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    /* Firefox < 16 */
    @-moz-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    /* Internet Explorer */
    @-ms-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    /* Opera < 12.1 */
    @-o-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
}

.fadeOut {
        -webkit-animation: fadeOut 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadeOut 2s; /* Firefox < 16 */
        -ms-animation: fadeOut 2s; /* Internet Explorer */
         -o-animation: fadeOut 2s; /* Opera < 12.1 */
            animation: fadeOut 2s;
}

    @keyframes fadeOut {
        from { opacity: 1; }
        to   { opacity: 0; }
    }

    /* Firefox < 16 */
    @-moz-keyframes fadeOut {
        from { opacity: 1; }
        to   { opacity: 0; }
    }

    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fadeOut {
        from { opacity: 1; }
        to   { opacity: 0; }
    }

    /* Internet Explorer */
    @-ms-keyframes fadeOut {
        from { opacity: 1; }
        to   { opacity: 0; }
    }

    /* Opera < 12.1 */
    @-o-keyframes fadeOut {
        from { opacity: 1; }
        to   { opacity: 0; }
}

.splashBox--container {
	position: absolute;
	bottom: -200px;
	left: -100px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 700px;
    overflow: hidden;
	transform: rotate(45deg);
}

.splashBox {
	margin-top: 16px;
	margin-left: 16px;
	height: 100px;
	width: 100px;
}

.splashBox--dark {
	background-color: #063852;
}

.splashBox--dark9s {
	background-color: #063852;
    animation: fadeIn 9s infinite ease 6s;
}

.splashBox--dark18s {
	background-color: #063852;
    animation: fadeIn 18s infinite ease 3s;
}

.splashBox--dark5s {
	background-color: #063852;
    animation: fadeIn 5s ease 3s;
}

.splashBox--light {
	background-color: #38b6ff;
}

.splashBox--light4s {
	background-color: #38b6ff;
    animation: fadeInOnStart 4s ease;
}
.splashBox--light6s {
	background-color: #38b6ff;
    animation: fadeIn 4s ease 2s;
}

.splashBox--light8s {
	background-color: #38b6ff;
    animation: fadeIn 8s infinite ease;
}

.splashBox--light15s {
	background-color: #38b6ff;
    animation: fadeIn 15s infinite ease;
}

@keyframes fadeIn {
  10%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}

@keyframes fadeInOnStart {
  0%  { opacity:0; }
  100% { opacity:1; }
}

.splashBar--container {
	position: absolute;
	top: 100px;
	right: -1100px;
	transform: rotate(45deg);
}

.splashBar--container-bottom {
	position: absolute;
	bottom: -91px;
	left: -912px;
	transform: rotate(220deg);
}

.splashBar {
	margin-top: 16px;
	margin-left: 16px;
	height: 300px;
	width: 2000px;
}

.splashBar--dark {
	background-color: #063852;
	margin-left: 500px;
	margin-top: -370px;
    animation: slideDown 3.5s;
}

.splashBar--light {
	background-color: #38b6ff;
    animation: slideUp 2.2s;
}

@keyframes slideUp {
  0% { transform: translateX(100%); }
  100% { transform: translateX(0%); }
}

@keyframes slideDown {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0%); }
}

.Text {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 65px;
    line-height: 98px;
    text-transform: uppercase;
    color: #414042;
    margin-right: 150px;
    /* width:900px; */

}

.Text:after {
  content: ' .';
  animation: dots 1.8s steps(5, end) infinite;}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color:  #414042;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0  #414042,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0  #414042,
      .5em 0 0  #414042;}}

.container--fullscreen-bottom {
	height: 100vh;
	width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.WelcomeText {
    display: flex;
    padding-top: 20%;
    margin: 0 auto;
    justify-content: center;
    animation: fadeIn ease 5s;
    -webkit-animation: fadeIn ease 5s;
    -moz-animation: fadeIn ease 5s;
    -o-animation: fadeIn ease 5s;
    -ms-animation: fadeIn ease 5s;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 80px;
    line-height: 105px;
    text-transform: uppercase;

color: #414042;
}
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
}
}